import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from '../components/Layout'
import '../components/base.css'
import { PrivacyPolicyQuery } from '../../graphql-types'
import DocumentContent from '../components/DocumentContent'
import { withPreview } from 'gatsby-source-prismic'
import extractGlobals from '../lib/extractGlobals'

const PrivacyPolicy: FC<PageProps<PrivacyPolicyQuery>> = ({ data }) => {
  const { meta, footer } = extractGlobals(data)
  return (
    <Layout tabNames={[]} meta={meta} footer={footer}>
      <DocumentContent
        title={data.prismicPrivacyPolicy?.data?.title?.text}
        content={data.prismicPrivacyPolicy?.data?.content?.html}
      />
    </Layout>
  )
}

export default withPreview(PrivacyPolicy)

export const pageQuery = graphql`
  query PrivacyPolicy {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
    prismicPrivacyPolicy {
      data {
        title {
          text
        }
        content {
          html
          raw
        }
      }
    }
  }
`
